<template>
  <div class="container">
    <div class="questions__title title">Вопросы и ответы</div>
    <div class="questions__text text">
      В данном разделе мы собрали ответы на самые часто задаваемые вопросы
      посетителей. Если среди наших ответов Вы так и не смогли найти ответ на
      свой вопрос, воспользуйтесь формой "Задать вопрос" и в ближайшее время мы
      обязательно на него ответим.
    </div>
    <div class="questions-tabs">
      <button
        class="questions-tab"
        :class="topic.id === selectQuestions.id ? 'questions-tab--active' : ''"
        v-for="topic in questions"
        :key="topic.id"
        @click.prevent="isShowQuestions(topic.id)"
      >
        {{ topic.name }}
      </button>
    </div>
    <div class="questions-content">
      <div
        class="questions-content__accordeon"
        v-for="item in selectQuestions.items"
        :key="item.id"
      >
        <Accordeon :item="item" :classItem="'accordeon--question'">
          <div class="typography" v-html="item.text"></div>
        </Accordeon>
      </div>
    </div>
  </div>
</template>

<script>
import Accordeon from "@/components/cards/accordeon";

import store from "@/store/index.js";

export default {
  components: {
    Accordeon,
  },

  beforeRouteEnter(to, from, next) {
    store.dispatch("HOME_FETCH_INFO_FAQ").then(() => {
      next();
    });
  },

  computed: {
    questions() {
      return this.$store.state.home.questions;
    },
  },

  data() {
    return {
      selectQuestions: this.$store.state.home.questions[0],
    };
  },

  methods: {
    isShowQuestions(id) {
      this.selectQuestions = this.questions.find((item) => item.id === id);
    },
  },
};
</script>
